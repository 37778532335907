import React, {useEffect, useState} from 'react';
import './ThemeSwitcher.css';
import night from "./night.png"
import day from "./cloudy.png"

interface ThemeSwitcherProps {
}

const ThemeSwitcher = ({}: ThemeSwitcherProps) => {
    const [theme, setTheme] = useState('dark');

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
    return (
        <img
            src={theme === 'light' ? night : day}
            alt="My logo"
            className={`logo ${theme === 'light' ? 'inverted' : ''}`}
            onClick={toggleTheme}
        />    );
};

export default ThemeSwitcher;