import React, {MouseEventHandler} from 'react';
import './PixelButton.css';

interface PixelButtonProps {
    text: string
    style?: React.CSSProperties
    onclick?: MouseEventHandler
}

const PixelButton = ({text,style,onclick}: PixelButtonProps) => {
    return (

        <button type="button" className="PixellButton" style={style} onClick={onclick}>{text}</button>

    );
};

export default PixelButton;