import React from 'react';
import './socials.css';
import linkedin from "../../assets/img/linkedin.png";
import github from "../../assets/img/github.svg";
import xIcon from "../../assets/img/x-letter.svg";

interface socialsProps {
    style?:any
}
const socialLinks = [
    {href: 'https://www.linkedin.com/in/awsaf-fida-mahmud-bbb115211/', icon: linkedin, label: "Linkedin"},
    {href: 'https://www.github.com/omelettech', icon: github, label: "Github"},
    {href: 'https://twitter.com/oml8tech', icon: xIcon, label: "Twitter"},
];
const Socials = (
    {style={display: 'flex', gap: '10px', alignItems: 'center', justifyContent: "center"}}: socialsProps) => {
    return (
        <div style={style}>
            {socialLinks.map((link, index) => (
                <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        fontSize: '18px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <img src={link.icon} alt="Icon" style={{width: '1.5rem', height: 'auto'}}/>
                    {link.label}

                </a>))}
        </div>
    );
};

export default Socials;