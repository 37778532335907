import React from 'react';
import "./About.css"
import DraggableWindow from "../../components/DraggableWindow/DraggableWindow";
import Tabs from "../../components/RetroForms/Tabs";
import {Link} from "react-router-dom";
import Socials from "../../components/socials/socials";

const as =
    " █████╗ ██████╗  ██████╗ ██╗   ██╗████████╗\n" +
    "██╔══██╗██╔══██╗██╔═══██╗██║   ██║╚══██╔══╝\n" +
    "███████║██████╔╝██║   ██║██║   ██║   ██║   \n" +
    "██╔══██║██╔══██╗██║   ██║██║   ██║   ██║   \n" +
    "██║  ██║██████╔╝╚██████╔╝╚██████╔╝   ██║   \n" +
    "╚═╝  ╚═╝╚═════╝  ╚═════╝  ╚═════╝    ╚═╝   \n" +
    "                                           "


const About: React.FC = () => {
    return (
        <>
                <pre className={"ascii-title"}>
                {as}
                </pre>

            <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                <div style={{flex: 1}} className={"sidebar"}>

                    <h1>Awsaf Fida Mahmud</h1>
                    <h2> Full Stack Developer</h2>
                    <h3>Programming student</h3>
                    <Socials style={{}}></Socials>

                </div>
                <div style={{flex: 2, fontFamily:"sans-serif"}} className={"main"}>
                    <h2>Overview</h2>

                    {/* ,-----.                 ,--.                   ,--.          */}
                    {/* '  .--./ ,---. ,--,--, ,-'  '-. ,---. ,--,--, ,-'  '-.       */}
                    {/* |  |    | .-. ||      \'-.  .-'| .-. :|      \'-.  .-'       */}
                    {/* '  '--'\' '-' '|  ||  |  |  |  \   --.|  ||  |  |  |          */}
                    {/* `-----' `---' `--''--'  `--'   `----'`--''--'  `--'          */}


                    <p>
                        Welcome! I’m Awsaf Fida, a Software developer with a knack for Full stack development and
                        Mobile Apps. Currently a student at George Brown College and I am graduating in <strong>May 2025</strong>, where I am currently planning,
                        designing and developing a <strong>Mobile application</strong> with React Native and Django with 2 other interested developers.
                    </p>
                    <br/>
                    <p>
                        I’m committed to learning new and exciting ideas, and I have specialized in
                        Full stack development, Database management, System Architecture Design, <Link to={"/skills"}>and
                        more</Link>, utilizing tools and
                        technologies like React, React Native, Python, Java, Javascript, <Link to={"/skills"}>and
                        more</Link> to create meaningful, measurable outcomes.
                    </p>
                    <br/>
                    <p>Beyond the tech world, I enjoy Art and illustration, which fuels my creativity and brings fresh
                        perspectives to my work.
                        <a href={"https://omelettedrawz.com"}> Omelettedrawz </a> is a Website I designed and developed
                        to showcase my hobbyist pursuit.

                    </p>
                    <br/>
                    <strong><p>If you're looking to connect, collaborate, or simply learn more about my journey, feel free to
                        reach out—I’m always excited to meet like-minded individuals and explore new opportunities!</p>
                    </strong>
                </div>
            </div>
        </>
    );
};

export default About;
