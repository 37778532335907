import React from 'react';
import './TechIcons.css';
import {FaReact, FaPython, FaNodeJs, FaHtml5, FaCss3Alt, FaDatabase, FaGithub, FaJsSquare} from 'react-icons/fa'; // Example icons from react-icons
import { SiDjango } from "react-icons/si";
import {RiJavascriptFill} from "react-icons/ri";

interface TechIconsProps {
    techs:string[]
}



const iconMapping: any = {
    react: <FaReact />,
    typescript: <FaJsSquare />, // Using JS icon for TypeScript
    django:<SiDjango/>,
    node: <FaNodeJs />,
    css: <FaCss3Alt />,
    html: <FaHtml5 />,
    github: <FaGithub />, // Optional: add other icons as needed
    python:<FaPython/>,
    javascript:<RiJavascriptFill/>,
};

const TechIcons = ({techs}:TechIconsProps) => {
    return (
        <div className="tech-icons-container">
            {techs.map((tech, index) => {
                const techName = tech.toLowerCase();
                const IconComponent = iconMapping[techName];

                return (
                    <div key={index} className="tech-icon">
                        <div className="icon-container">
                            {IconComponent || <span>?</span>} {/* Fallback if icon is not found */}
                        </div>
                        <div className="tooltip">
                            <span className="tooltiptext">{tech}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TechIcons;