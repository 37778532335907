import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./Navbar.css"
import Logo from "../../assets/img/logo.png"
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";

const Navbar:React.FC = () =>{
    const [isOpen,setIsOpen]=useState(false)

    function toggleMenu() {
        isOpen? setIsOpen(false):setIsOpen(true)
    }

    return(
        <nav className={"header"}>
            <div className="navbar">
                <div style={{height:"100%",alignItems:"center",display:"flex"}}>
                    {/*<Link to="/"></Link>*/}
                    <ThemeSwitcher></ThemeSwitcher>
                </div>


                <ul className={`nav-list ${isOpen && window.innerWidth<=768? "active" : ""}`}>
                    <li className="nav-list-items"><Link to="/">Home</Link></li>
                    <li className="nav-list-items"><Link to="/about">About</Link></li>
                    <li className="nav-list-items"><Link to="/portfolio">Portfolio</Link></li>
                    <li className="nav-list-items"><Link to="/resume">Resume</Link></li>
                    <li className="nav-list-items"><Link to="/contact">Contact</Link></li>
                </ul>

                <button className={`hamburger ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
                    |||
                </button>

            </div>
        </nav>
    )
}

export default Navbar;