import React from 'react';

interface ResumeItemProps {
    title: string;
    subtitle: string;
    description?: string[];
    date: string;
}

const ResumeItem: React.FC<ResumeItemProps> = ({title, subtitle, description, date}) => {
    return (
        <div className="resume-item">
            <div className="timeline-date">
                <span>{date}</span>
            </div>
            <div className="timeline-content">
                <h3 style={{color: "var(--color-secondary)"}}>{title}</h3>
                <h4>{subtitle}</h4>
                <ul>
                    {description && description.map((listItem, index) => (
                            <li>{listItem}</li>
                        )
                    )}
                </ul>
            </div>
        </div>
    );
};

export default ResumeItem;
