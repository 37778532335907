import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Navbar from "./components/Navbar/Navbar";
import './assets/css/forms.css';
import './assets/css/utilityClasses.css';
import ProjectForm from "./pages/_AddProject/AddProject";
import Portfolio from "./pages/Portfolio/Portfolio";
import ProjectPage from "./pages/Portfolio/ProjectPage";
import Resume from "./pages/Resume/Resume";
import Socials from "./components/socials/socials";
import { Analytics } from "@vercel/analytics/react"
import ScrollToTop from "./components/_scrollToTop/_scrollToTop";

function App() {


    return (

        <Router>

            <ScrollToTop></ScrollToTop>

            <Navbar></Navbar>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/portfolio" element={<Portfolio/>}/>

                <Route path={"/add"} element={<ProjectForm/>}/>
                <Route path="/project/:projectId" element={<ProjectPage/>}/>


                <Route path="/resume" element={<Resume/>}/>
            </Routes>
            <footer>


                <div className="footer-container">

                    <div className="footer-section">
                        <h3>Quick links</h3>
                        <Socials></Socials>
                        <Analytics></Analytics>

                    </div>
                </div>
                <a href="https://www.flaticon.com/free-icons/pixelated" target={"_blank"} title="pixelated icons" style={{fontSize:"0.75rem"}}>Pixelated
                    icons created by Freepik - Flaticon</a>
                <div className="footer-bottom">
                    <p>&copy; 2024 Awsaf Fida Mahmud. All rights reserved.</p>
                </div>
            </footer>
        </Router>

    );
}

export default App;
