import React, {useState} from 'react';
import {Project} from "./types"
import ProjectCard from "./ProjectCard";
import {Link, useNavigate} from "react-router-dom";
import {project_list as projects} from "./project_list";
import Tabs from "../../components/RetroForms/Tabs";


const title =
    " ______  ______  ______  ______  ______  ______  __      __  ______   \n" +
    "/\\  == \\/\\  __ \\/\\  == \\/\\__  _\\/\\  ___\\/\\  __ \\/\\ \\    /\\ \\/\\  __ \\  \n" +
    "\\ \\  _-/\\ \\ \\/\\ \\ \\  __<\\/_/\\ \\/\\ \\  __\\\\ \\ \\/\\ \\ \\ \\___\\ \\ \\ \\ \\/\\ \\ \n" +
    " \\ \\_\\   \\ \\_____\\ \\_\\ \\_\\ \\ \\_\\ \\ \\_\\   \\ \\_____\\ \\_____\\ \\_\\ \\_____\\\n" +
    "  \\/_/    \\/_____/\\/_/ /_/  \\/_/  \\/_/    \\/_____/\\/_____/\\/_/\\/_____/"
const Portfolio: React.FC = () => {
    const [selectedTag, setSelectedTag] = useState("All");
    const navigate = useNavigate()
    const filteredProjects = selectedTag === "All"
        ? projects
        : projects.filter((project) => project.technologies.includes(selectedTag));

    function handleClick(id: number) {
        window.scrollTo(0, 0)
        return navigate(`/project/${id}`)

    }

return (
    <div>
            <pre className={"ascii-title"}>
                {title}
             </pre>
        <Tabs tabs={["All", "Javascript", "Python", "React", "Django", "Java"]} onSelectTab={setSelectedTag}></Tabs>

        <div className={"project-card-container"}>

            {filteredProjects.map((project, id) => {
                return <ProjectCard project={project} key={id} onClick={()=>handleClick(project.id)}></ProjectCard>
            })}
        </div>


    </div>
);
}
;

export default Portfolio;
