import React, {useState, useRef, ReactNode, MouseEventHandler} from 'react';
import './DraggableWindow.css'; // Import CSS for styling

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

interface DraggableWindowInterface {
    x: number
    y: number
    children: ReactNode
    title?: string
    onclick?: MouseEventHandler
}

const DraggableWindow = ({x, y, children, title = "Untitled", onclick}: DraggableWindowInterface) => {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({x: x, y: y});
    const dragRef = useRef<{ clientX: number; clientY: number } | null>(null);
    let dragThreshold = 2
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        dragRef.current = {clientX: e.clientX, clientY: e.clientY};
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging || !dragRef.current) return;

        const newX = position.x + (e.clientX - dragRef.current.clientX);
        const newY = position.y + (e.clientY - dragRef.current.clientY);

        // Limit the position to stay within the viewport
        const elementWidth = 300; // Change to your window width
        const elementHeight = 200; // Change to your window height

        const boundedX = Math.max(0, Math.min(newX, windowWidth - elementWidth));
        const boundedY = Math.max(60, Math.min(newY, windowHeight - elementHeight));

        setPosition({x: boundedX, y: boundedY});
        dragRef.current = {clientX: e.clientX, clientY: e.clientY};
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setIsDragging(true)
        const touch = e.touches[0];
        dragRef.current = {clientX: touch.clientX, clientY: touch.clientY};
        document.body.style.overflow = 'hidden';
    }

    const handleTouchEnd = () => {
        setIsDragging(false)
        document.body.style.overflow = '';
    }
    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging || !dragRef.current) return;

        const touch = e.touches[0];
        let deltaX = touch.clientX - dragRef.current.clientX;
        let deltaY = touch.clientY - dragRef.current.clientY;

        if (Math.abs(deltaX - position.x) > dragThreshold || Math.abs(deltaY - position.y) > dragThreshold) {
            const newX = position.x + deltaX
            const newY = position.y + deltaY
            setPosition({x: newX, y: newY});
        }
    }


    // Add event listeners for mouse move and mouse up
    React.useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);


        };
    },);

    React.useEffect(() => {
        if (isDragging) {
            window.addEventListener('touchmove', handleTouchMove as any);
            window.addEventListener('touchend', handleTouchEnd as any);
        } else {
            window.removeEventListener('touchmove', handleTouchMove as any);
            window.removeEventListener('touchend', handleTouchEnd as any);
        }

        return () => {
            window.removeEventListener('touchmove', handleTouchMove as any);
            window.removeEventListener('touchend', handleTouchEnd as any);
        };
    }, [isDragging]);

    return (
        <div
            className="draggable-window"
            style={{left: position.x, top: position.y}}
        >
            <div className="window-header" onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
                <div className="title-bar-text">{title}</div>
                <div className="title-bar-controls">
                    <button className={"window98-button"} onClick={onclick}>X</button>

                </div>
            </div>
            <div className="window">
                {children}
            </div>
        </div>
    );
};

export default DraggableWindow;
