import React from 'react';
import {Project} from "./types"
import "./ProjectCard.css"
import {Link, useNavigate} from "react-router-dom";
import TechIcons from "../../components/TechIcons/TechIcons";
interface ProjectCardProps {
    project: Project;
    onClick?:any;
}

//Art Haven
// An immersive art platform that showcases original artwork in a custom gallery, offers prints and merchandise in an online shop, and allows users to request commissions from the artist.
//
// Overview
// Art Haven is a full-featured web platform designed to help artists showcase their work, sell products, and take commission requests. Built with a focus on user experience, the site allows users to browse high-quality images in the gallery, purchase prints and other merchandise, and directly request personalized commissioned artwork. It’s a one-stop solution for artists looking to establish a professional online presence.
//
// Key Features:
// Custom gallery with high-resolution image viewing and categorization
// Shop with dynamic product listings, secure payment, and order tracking
// Commission request form with options for custom specifications
// Technology Stack: React, Django, MongoDB, Firebase Authentication, Stripe API for payments
// Live Demo / Links
// Live Demo (if applicable)
// GitHub Repository - Full-stack code, including frontend components, backend API routes, and database schema.
// Screenshots / Media
//
// A sample view of the gallery section showcasing various artwork categories.
//
// Technical Details
// Architecture: Built with a decoupled frontend (React) and backend (Django), where the frontend interacts with REST APIs to retrieve gallery items, products, and user-specific orders.
// Libraries / Frameworks:
// React for the frontend, leveraging component-based design for reusability
// Django REST Framework for backend API creation
// MongoDB for managing product and commission data
// Stripe API for secure payment processing in the shop
// Key Challenges:
// Image Optimization: Resized and compressed images on the backend before serving them in the gallery to maintain high quality without impacting load times.
// Dynamic Attributes: Handled different product attributes (sizes, colors, formats) for customizable merchandise in the shop.
// Commission Workflow: Developed a structured form for commission requests, with input validation and submission tracking for user convenience.
// Testing: Used Jest and Cypress for front-end testing and Django’s built-in test framework for backend testing.
// Lessons Learned
// This project taught me about optimizing high-resolution images for the web and managing dynamic data structures for different product attributes. It also deepened my experience with secure payment integrations, enabling a smooth checkout process for users.
//
// Future Enhancements
// Add a user dashboard for tracking orders and commission status.
// Implement a review system for purchased products.
// Expand the commission form to include more customization options.

const ProjectCard = ({project,onClick}: ProjectCardProps) => {
    const navigate = useNavigate()

    return (
        <div className="window98-card">
            <div className="window98-title-bar">
                <span className="window98-title">{project.title + ".exe"}</span>
                <div className="window98-buttons">
                    <span className="window98-button">?</span>
                    <Link to={`/project/${project.id}`} className="window98-button">□</Link>
                    {/*<span className="window98-button">×</span>*/}
                    {project.hosted && project.hosted_link && <Link to={project.hosted_link} className={"window98-button"}>↗</Link>}
                </div>
            </div>



            <div className="window98-content">
                <img className="cover-img" src={project.cover_img.src} alt={project.cover_img.alt}/>

                <div style={{width:"100%", cursor:"pointer"}} className={"window"} onClick={onClick}>
                    <h1>{project.title}</h1>
                    <h2>{project.subtitle}</h2>
                    <h3>{project.description}</h3>
                    <div className={"tech-stack"}>
                        {/*{project.technologies.map((tech, id) => (*/}
                        {/*    <div key={id}>{tech}</div>*/}
                        {/*))}*/}
                        <TechIcons techs={project.technologies}></TechIcons>
                    </div>
                    {project.hosted &&
                        <div style={{textAlign:"right",color:"red",fontSize:"1.5rem"}}>
                            🔴 Live
                        </div>
                    }

                </div>

            </div>


        </div>

    );
};

export default ProjectCard;