import React, { useState } from 'react';

function ProjectForm() {
    const [projects, setProjects] = useState([]);
    const [newProject, setNewProject] = useState({ title: "", description: "", tags: "", link: "" });
    const [fileHandle, setFileHandle] = useState(null);

    // Open JSON file and load data
    const openFile = async () => {
        try {
            const [handle] = await window.showOpenFilePicker({
                types: [{
                    description: 'JSON Files',
                    accept: { 'application/json': ['.json'] }
                }],
            });
            setFileHandle(handle);

            const file = await handle.getFile();
            const content = await file.text();
            const json = JSON.parse(content);

            setProjects(json);
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewProject({ ...newProject, [name]: value });
    };

    // Add project to local state
    const addProject = () => {
        const updatedProjects = [...projects, { ...newProject, id: (projects.length + 1).toString() }];
        setProjects(updatedProjects);
        setNewProject({ title: "", description: "", tags: "", link: "" });
    };

    // Save updated projects array to JSON file
    const saveFile = async () => {
        if (!fileHandle) {
            alert("Please open a JSON file first.");
            return;
        }

        try {
            const writable = await fileHandle.createWritable();
            await writable.write(JSON.stringify(projects, null, 2));
            await writable.close();
            alert("File saved successfully!");
        } catch (error) {
            console.error('Error saving file:', error);
        }
    };

    return (
        <div>
            <button onClick={openFile}>Open JSON File</button>

            {fileHandle && <div>
                <h2>Add New ProjectPage</h2>
                <input
                    type="text"
                    name="title"
                    placeholder="ProjectPage Title"
                    value={newProject.title}
                    onChange={handleChange}
                />
                <input
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={newProject.description}
                    onChange={handleChange}
                />
                <input
                    type="text"
                    name="tags"
                    placeholder="Tags (comma-separated)"
                    value={newProject.tags}
                    onChange={(e) => setNewProject({ ...newProject, tags: e.target.value.split(",").map(tag => tag.trim()) })}
                />
                <input
                    type="text"
                    name="link"
                    placeholder="ProjectPage Link"
                    value={newProject.link}
                    onChange={handleChange}
                />
                <button onClick={addProject}>Add ProjectPage</button>

                <button onClick={saveFile}>Save to JSON</button>

                <h2>Current Projects</h2>
                <ul>
                    {projects.map((project) => (
                        <li key={project.id}>
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            <p>Tags: {project.tags.join(", ")}</p>
                            <a href={project.link}>ProjectPage Link</a>
                        </li>
                    ))}
                </ul>
            </div>}
        </div>
    );
}

export default ProjectForm;
