export interface Command {
    command: string;
    process: () => void;
    description: string;
}
let static_commands: Command[];
static_commands = [
    {
        command: 'oi',
        process: () => {
            alert("Why?")
        },
        description: 'just because i can',
    },

];
export default static_commands
