import React from 'react';
import Socials from "../../components/socials/socials";
import DraggableWindow from "../../components/DraggableWindow/DraggableWindow";

const title = "" +
    " .o88b.  .d88b.  d8b   db d888888b  .d8b.   .o88b. d888888b \n" +
    "d8P  Y8 .8P  Y8. 888o  88 `~~88~~' d8' `8b d8P  Y8 `~~88~~' \n" +
    "8P      88    88 88V8o 88    88    88ooo88 8P         88    \n" +
    "8b      88    88 88 V8o88    88    88~~~88 8b         88    \n" +
    "Y8b  d8 `8b  d8' 88  V888    88    88   88 Y8b  d8    88    \n" +
    " `Y88P'  `Y88P'  VP   V8P    YP    YP   YP  `Y88P'    YP    \n" +
    "                                                            \n" +
    "                                                            "
const Contact: React.FC = () => {
    return (
        <>
            <pre className={"ascii-title"}>
                {title}
             </pre>
            <DraggableWindow x={200} y={200} title={"contact form"}>

                <Socials></Socials>
                <div style={{width:"60vw"}}>
                    <form id="contact-form" action="https://formspree.io/f/mleyyako" method="POST" style=
                        {{
                            color: "#000000",
                            fontFamily: "'Ubuntu Mono',monospace",
                            fontSize: "2.2vh",
                            padding: 10,
                            display: " flex",
                            flexDirection: "column",

                        }}>
                        <label htmlFor="fname">Your Name:</label>
                        <input type="text" id="fname" name="firstname" placeholder="John Doe" required/>
                        <label htmlFor="email">Your email:</label>
                        <input type="email" id="email" name="email" placeholder="doejohn@example.com" required/>

                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" placeholder="Write something..." required></textarea>


                    </form>
                    <button type="submit" form="contact-form" className="PixellButton">Submit</button>
                </div>


            </DraggableWindow>
            <div style={{height:600}}></div>

        </>
    );
};

export default Contact;
