import React from 'react';
import PixelButton from "../../components/PixelButton/PixelButton";
import ResumeItem from './ResumeItem';
import "./Resume.css"
import Socials from "../../components/socials/socials";
import {Link} from "react-router-dom";

const experiences = [
    {
        title: 'Teaching Assistant',
        subtitle: 'George Brown College | 160 Kendall Ave, Toronto, ON',
        description: [
            'Provided hands-on, assistance to over dozen students every week maintaining academic integrity',
            ' Offered on demand peer tutoring services for 3 different programs and over 15 distinct courses, with\n' +
            'more than 200 potential students',
            'Organized and conducted regular on-demand tutoring sessions to students with disabilities and build\n' +
            'confidence in their academic skills',
            'Monitored traffic of open lab sessions during the summer'
        ],
        date: 'Jan 2024 - Present',
    },
    {
        title: 'Accessibility Peer Tutor',
        subtitle: 'George Brown College | 160 Kendall Ave, Toronto, ON',
        description: [
            'Organized and planned individual sessions with 4 students with disabilities and build confidence in their\n' +
            'academic skills considering the best accessible practices',
            'Collaborated with adaptive technologists to adapt tutoring sessions for each student and gathered\n' +
            'teaching materials punctually',
            'Instructed and discussed more than 5 different computer science courses relevant to respective course\n' +
            'outlines, each session being on average 2 hours both online and on-campus',
            'Maintained a respectful learning environment by encouraging questions and creative thinking\n'
        ],
        date: 'Oct 2024 - Present',
    },
    {
        title: 'Customer Service Representative',
        subtitle: 'Pizzaiolo | 104 Yonge St, Toronto, ON',
        description: [
            'Processed over 70 customers’ orders, ensuring accuracy throughout the interaction.',
            'Actively participated in the preparation of menu items, adhering to quality standards',
            'Adapted to high-pressure situations during peak hours, maintaining composure and efficiency while fulfilling orders',
            'Proactively communicate with kitchen staff and provided assistance to maintain hygiene'
        ],
        date: 'Jan 2023 - Nov 2023',
    },
];

const education = [
    {
        title: 'Programming and Analysis',
        subtitle: 'George Brown College',
        description: [
            'Ranked top 30 on IEEExtreme 17 country-wide with my team',
        ],
        date: 'Jan 2022 - Present',
    },
    {
        title: 'Cambridge AICE certificate',
        subtitle: 'European Standard School | Dhaka, Bangladesh',
        description: [
            'Co-Founded the STEM club with 70+ active members',
        ],
        date: 'Jun 2019 - Jun 2021',
    },
];

const projects = [
    {
        title: 'Art gallery App',
        subtitle: 'Personal Project',
        description: [
            'Developed a full-stack e-commerce application using Django REST Framework, SQL database and React.',
            'Implemented secure user authentication',
            'Integrated 3rd party APIs like Printful API and Stripe API',
            'Designed a user-friendly interface with efficient product navigation.'
        ],
        date: 'Work In Progress',
    },
    {
        title: '__init__(self)',
        subtitle: 'Personal Project',
        description: [
            'Built a responsive portfolio website using React and TypeScript.',
            'Introduced additional features like Minesweeper and a Console experience',
            'Integrated animations and transitions for an engaging user experience.',
            'Deployed using Vercel for continuous deployment and easy updates.'
        ],
        date: '2024',
    },

];
const Resume: React.FC = () => {
    return (
        <div className={"resume-section"} style={{display: "flex", flexDirection: "column"}}>
            <PixelButton text={"Download PDF"}></PixelButton>

            <div style={{textAlign: 'center'}}>
                <h1 style={{marginBottom: "0"}}>Awsaf Fida Mahmud</h1>
                <div>
                    fidaawsaf@gmail.com | 647-382-3946 | Toronto, ON<br/>
                    <Socials></Socials>
                </div>
            </div>

            <div className={"two-cols"}>
                <div style={{flex: 2}}>
                    <div className={"resume-section"}>
                        <h2>Experience</h2>
                        {experiences.map((exp, index) => (
                            <ResumeItem key={index} {...exp} />
                        ))}
                    </div>

                    <div className={"resume-section"}>
                        <h2>Education</h2>
                        {education.map((edu, index) => (
                            <ResumeItem key={index} {...edu} />
                        ))}
                    </div>
                </div>


                <div style={{flex: 1}}>
                    <div className={"resume-section"}>
                        <h2>Projects</h2>
                        {projects.map((proj, index) => (
                            <ResumeItem key={index} {...proj} />
                        ))}
                        <Link to={"/portfolio"} style={{color:"lightblue",textDecoration:"underline"}}>See more</Link>

                    </div>
                </div>
            </div>

        </div>);
};

export default Resume;
