import React, {useState} from 'react';
import DraggableWindow from "../../components/DraggableWindow/DraggableWindow";
import Minesweeper from "../../components/Minesweeper/Minesweeper";
import Console from "../../components/Console/Console";
import Socials from "../../components/socials/socials";
import {Link} from "react-router-dom";
const as =
    "██╗  ██╗ ██████╗ ███╗   ███╗███████╗\n" +
    "██║  ██║██╔═══██╗████╗ ████║██╔════╝\n" +
    "███████║██║   ██║██╔████╔██║█████╗  \n" +
    "██╔══██║██║   ██║██║╚██╔╝██║██╔══╝  \n" +
    "██║  ██║╚██████╔╝██║ ╚═╝ ██║███████╗\n" +
    "╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝\n" +
    "                                    "
let windowWidth = window.innerWidth
const Home: React.FC = () => {
    const [window, setWindow] = useState(true)
    return (
        <>
            {window && <DraggableWindow x={windowWidth/2.5} y={200} title={"About me"} onclick={() => {
                setWindow(false)
            }}>
                <h1>Awsaf Fida Mahmud</h1>
                <h2>Software Developer</h2>
                <Socials></Socials>
                <h3>A good enough programmer.</h3>
                <Link to={"/about"} style={{color:"blue"}}>Learn more</Link>
                    <img
                        src={"https://github-readme-streak-stats.herokuapp.com?user=omelettech&theme=dark&background=000000"}
                        alt={"Github details"}
                        style={{width:"100%",marginTop:"1rem"}}
                    />


            </DraggableWindow>}
            <DraggableWindow x={300} y={500}>
                <Minesweeper></Minesweeper>
            </DraggableWindow>
            <Console></Console>


        </>
    );
};

export default Home;
