// Tabs.tsx
import React, { useState } from 'react';
import "./RetroForms.css"
type TabProps = {
    tabs: string[];
    onSelectTab: Function
};

const Tabs: React.FC<TabProps> = ({ tabs,onSelectTab }) => {
    const [activeTab, setActiveTab] = useState("All");

    const handleClick = (tab:string)=>{
        setActiveTab(tab)
        onSelectTab(tab)
    }

    return (
        <div className="retro-tabs">
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`retro-tab ${activeTab === tab ?"active":""}`}
                    onClick={() => handleClick(tab)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
